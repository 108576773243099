import { Component, OnInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../environments/environment';
import { Router } from '@angular/router';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { NationalHolidayDialogComponent } from './national-holiday-dialog/national-holiday-dialog.component';
import * as moment from 'moment';

// 祝日データ
export interface nationalHoliday {
  holiday: string;
  classification: number;
  note: string;
}

@Component({
  selector: 'app-national-holiday',
  templateUrl: './national-holiday.component.html',
  styleUrls: ['./national-holiday.component.less']
})

export class NationalHolidayComponent implements OnInit {

  // カラム名
  public columnDefs = [
    'day',
    'classification',
    'note',
    'edit',
    'deletion'
  ];

  filterYear = (new Date).getFullYear();
  filterMonth = "";
  filterDay = "";
  filterClassification = "";
  filterMemo = "";

  classifications = [
    {key: "", value: "全て"},
    {key: "0", value: "祝日"},
    {key: "1", value: "祝日(毎年)"},
    {key: "2", value: "全社休日"},
    {key: "3", value: "全社休日(毎年)"}
  ];

  // テーブルデータソース
  public nationalHolidayList = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  constructor(private http: HttpClient, private router: Router, private dialog: MatDialog, private cdr: ChangeDetectorRef, private ngZone: NgZone) { }

  ngOnInit() {
    this.getHolidayData();
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  ngAfterViewInit() {
    this.dialog.afterAllClosed.subscribe(res => {
      this.getHolidayData();
    })
  }

  /**
   * フィルター変更 イベント
   */
  changeFilter() {
    this.getHolidayData();
  }

  /**
   * 祝祭日データ取得
   */
  getHolidayData() {
    // 年 必須
    if (!this.filterYear) {
      this.nationalHolidayList = new MatTableDataSource([]);
      return;
    }
    this.http.get(
      API_BASE_URL + '/api/v1/nationalHoliday?'
      + "&filterYear="+ encodeURIComponent(String(this.filterYear))
      + "&filterMonth=" + encodeURIComponent(String(this.filterMonth))
      + "&filterDay=" + encodeURIComponent(String(this.filterDay))
      + "&filterClassification=" + encodeURIComponent(this.filterClassification)
      + "&filterMemo=" + encodeURIComponent(this.filterMemo),
      {headers: this.headers}
    )
    .subscribe(res => {
      this.nationalHolidayList = new MatTableDataSource(res as nationalHoliday[]);
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * ダイアログ表示
   * @param event 祝日データ
   */
  openDialog(event) {
    this.dialog.open(NationalHolidayDialogComponent, {
      data: {
        data: event
      }
    });
  }

  /**
   * 削除
   * @param event 削除するデータ
   */
  delete(event) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-LoginAccessKey": localStorage.getItem("accessKey")
      })
    };

    if (event.note == null) {
      event.note = '';
    }
    if (window.confirm(moment(event.holiday).format('YYYY/MM/DD') + ' ' + event.note + " を削除してよろしいでしょうか？")) {
      this.http.post(
        API_BASE_URL + "/api/v1/nationalHoliday/remove", event, httpOptions
      )
      .subscribe(res => {
        this.getHolidayData();
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
      });
    }
  }

  /**
   * 区分名表示
   * @param event　区分 
   * @returns 区分名
   */
  getClassificationName(event: number) {
    var name: string;
    switch (event) {
      case 0:
        name = '祝日'
        break;
      case 1:
        name = '祝日 (毎年)'
        break;
      case 2:
        name = '全社休日'
        break;
      case 3:
        name = '全社休日 (毎年)'
        break;
    }
    return name;
  }
}
